import React, { Component } from 'react'

import '../css/main.css'
import '../css/fonts.css'

import Seo from '../components/SeoHead'
import RedeemContent from '../components/Redeem/'

export default class Redeem extends Component {
  render() {
    return (
      <>
        <Seo />
        <RedeemContent />
      </>
    )
  }
}
